




















































































































































import Vue from "vue";
import axios from "axios";
import { FindingOverview } from "@/types/finding";

export default Vue.extend({
  name: "DemonstrationComponent",

  data: () => ({
    app_name: process.env.VUE_APP_TITLE,
    files: [],
    findings: [] as FindingOverview[],
    loading: false,
    isFormValid: false,
    fileInputRules: [
      (files: any) =>
        files.reduce((sum: number, current: any) => sum + current.size, 0) <
          20000000 || "Upload maximaal 20 MB aan bestanden per keer.",
    ],
  }),
  methods: {
    postFilesForValidation() {
      this.$data.loading = true;
      const formData = new FormData();
      for (let i = 0; i < this.$data.files.length; i++) {
        formData.append("documents", this.$data.files[i]);
      }
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      axios
        .post(`${this.$store.state.APIurl}/document/demo-dry`, formData, {
          headers: headers,
        })
        .then((response) => {
          this.$data.findings = response.data;
          this.$data.loading = false;
        })
        .catch((_) => {
          this.$data.findings = [];
          this.$data.loading = false;
        });
    },
    performDemonstration(demo: string) {
      this.$data.loading = true;
      axios
        .post(`${this.$store.state.APIurl}/document/demo-examples-files`, demo)
        .then((response) => {
          this.$data.findings = response.data;
          this.$data.loading = false;
        })
        .catch((_) => {
          this.$data.findings = [];
          this.$data.loading = false;
        });
    },
  },
});
